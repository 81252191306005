import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledTypography = styled(Typography)`
  && {
    font-weight: 700;
    margin: 25px 0;
  }
`

export const ButtonsContainer = styled(Box)`
  width: 320px;
  .MuiButton-fullWidth:first-of-type {
    margin-bottom: 10px;
  }
`
export const Container = styled(Box)``
