import { Box } from '@mui/material'
import styled, { css, keyframes } from 'styled-components'

interface ContainerProps {
  active?: boolean
  size?: number
}

const rotate = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }
`

export const Container = styled(Box)<ContainerProps>`
  ${({ theme, active }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: ${theme.palette.primary[500]};
    margin: auto 0 auto 16px;
    border-radius: 999px;
    cursor: pointer;
    padding: 1px;
    transition: all 0.3s;
    svg {
      width: 100%;
      height: 100%;
    }
    ${active &&
    css`
      width: 18px;
      height: 18px;
      svg {
        animation: ${rotate} 0.3s forwards;
      }
    `}
  `}
`
