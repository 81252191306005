import { Checkbox } from '@mui/material'
import styled, { css } from 'styled-components'
import Input from '../Input'

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 22px;
    left: 15px;
    display: flex;
    flex-direction: row;
    border: 1px solid ${theme.palette.common.white};
    border-radius: 10px;
    background: ${theme.palette.primary['400']};
    width: 200px;
    z-index: 2000;
  `}
`
export const StyledInput = styled(Input)`
  min-height: unset;
  .MuiInputBase-input {
    padding-right: 0;
  }
`
export const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: unset;
  }
`
