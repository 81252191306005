import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box } from '@mui/material'
import clsx from 'clsx'
import React, { CSSProperties, ReactElement, ReactNode } from 'react'
import {
  Body,
  Container,
  ContainerProps,
  Data,
  Head,
  Row,
  StyledScrollableBox,
  Wrapper,
} from './Table.elements'

interface Props {
  children: ReactNode
  className?: string
  style?: CSSProperties
  scrollableTable?: boolean
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  HeaderComponent?: ReactNode
  FooterComponent?: ReactNode
  stickyHead?: boolean
  stickyLastColumn?: boolean
}

function Table({
  children,
  className,
  style,
  scrollableTable,
  width,
  maxWidth,
  height,
  maxHeight,
  HeaderComponent,
  FooterComponent,
  stickyHead,
  stickyLastColumn,
}: Props): ReactElement {
  const renderTable = (props?: ContainerProps) => {
    return (
      <Container
        className={className}
        style={style}
        stickyHead={stickyHead}
        stickyLastColumn={stickyLastColumn}
        {...props}
      >
        {children}
      </Container>
    )
  }
  if (scrollableTable) {
    return (
      <Layout
        maxWidth={maxWidth}
        HeaderComponent={HeaderComponent}
        FooterComponent={FooterComponent}
      >
        <StyledScrollableBox
          scrollDirection="both"
          width={width}
          maxWidth={maxWidth}
          height={height}
          maxHeight={maxHeight}
        >
          {renderTable()}
        </StyledScrollableBox>
      </Layout>
    )
  }
  return (
    <Layout
      maxWidth={maxWidth}
      HeaderComponent={HeaderComponent}
      FooterComponent={FooterComponent}
    >
      {renderTable({ width, maxWidth, height, maxHeight })}
    </Layout>
  )
}

export default Table

interface LayoutProps {
  children: ReactNode
  maxWidth?: string
  HeaderComponent?: ReactNode
  FooterComponent?: ReactNode
}
const Layout = ({
  children,
  maxWidth,
  HeaderComponent,
  FooterComponent,
}: LayoutProps): ReactElement => {
  return (
    <Wrapper maxWidth={maxWidth}>
      {HeaderComponent && HeaderComponent}
      {children}
      {FooterComponent && FooterComponent}
    </Wrapper>
  )
}
/**
 * Compound components
 */
interface TableHeadProps {
  children: ReactNode
  className?: string
}

function TableHead({ children, className }: TableHeadProps) {
  return <Head className={className}>{children}</Head>
}

interface TableBodyProps {
  children: ReactNode
  className?: string
}

function TableBody({ children, className }: TableBodyProps) {
  return <Body className={className}>{children}</Body>
}

interface TableRowProps {
  id?: string
  children: ReactNode
  className?: string
}
function TableRow({ id, children, className }: TableRowProps) {
  return (
    <Row id={id} className={clsx('Table__row', className)}>
      {children}
    </Row>
  )
}

interface TableDataProps {
  children: ReactNode
  className?: string
  style?: CSSProperties
  textAlign?: 'left' | 'right' | 'center' | 'initial' | 'inherit'
  width?: string
  minWidth?: string
  maxWidth?: string
  onClick?: () => void
  enableSortingIcon?: 'asc' | 'desc'
}
function TableData({
  children,
  className,
  textAlign = 'initial',
  width,
  minWidth,
  maxWidth,
  onClick,
  enableSortingIcon,
  style,
}: TableDataProps) {
  let alignItems = 'initial'
  switch (alignItems) {
    case 'left':
      alignItems = 'flex-start'
      break
    case 'right':
      alignItems = 'flex-end'
      break
    default:
      alignItems = textAlign
  }
  return (
    <Data
      className={className}
      textAlign={textAlign}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onClick={onClick}
      style={style}
    >
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems={alignItems}
        >
          {children}
        </Box>
        {enableSortingIcon && (
          <Box
            display="flex"
            style={{
              transform:
                enableSortingIcon === 'asc' ? 'rotate(180deg)' : 'unset',
            }}
          >
            <ArrowDropDownIcon fontSize="small" />
          </Box>
        )}
      </Box>
    </Data>
  )
}

Table.Head = React.memo(TableHead)
Table.Body = React.memo(TableBody)
Table.Row = React.memo(TableRow)
Table.Data = React.memo(TableData)
