import { Theme, useMediaQuery } from '@mui/material'

interface ReturnType {
  isMobileSm: boolean
  isMobileLg: boolean
  isTabletSm: boolean
  isTabletLg: boolean
}
function useBreakpoints(): ReturnType {
  const isMobileSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.xs),
  )
  const isMobileLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.sm),
  )
  const isTabletSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.md),
  )
  const isTabletLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.lg),
  )
  return { isMobileSm, isMobileLg, isTabletSm, isTabletLg }
}

export default useBreakpoints
