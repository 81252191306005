import {
  ModalAction,
  ModalActionType,
  ModalState,
} from '../../interfaces/Modal'

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActionType.OPEN_MODAL:
      return {
        ...state,
        ...action.payload,
      }
    case ModalActionType.CLOSE_MODAL:
      return {
        ...state,
        ...action.payload,
      }

    default:
      throw new Error('Invalid modal reducer action')
  }
}

export default reducer
