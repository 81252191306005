import createBreakpoints from '@mui/system/createTheme/createBreakpoints'
export const breakpoints = createBreakpoints({
  values: {
    xs: 320,
    sm: 360,
    md: 744,
    lg: 1080,
    xl: 1920,
  },
})
