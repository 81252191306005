import { TypographyOptions } from '@mui/material/styles/createTypography'
import { breakpoints } from './breakpoints'

const DEFAULT_FONT_SIZE = 16
const pxToRem = (pixel: number) => `${pixel / DEFAULT_FONT_SIZE}rem`
const typography: TypographyOptions = {
  htmlFontSize: DEFAULT_FONT_SIZE,
  fontSize: DEFAULT_FONT_SIZE,
  fontFamily: ['Poppins', 'sans-serif'].join(','),
  fontWeightBlack: 900,
  h1: {
    fontSize: pxToRem(96),
    fontWeight: 600,
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: pxToRem(60),
    fontWeight: 600,
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: pxToRem(48),
    fontWeight: 600,
  },
  h4: {
    fontSize: pxToRem(34),
    fontWeight: 600,
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 600,
    [breakpoints.up(breakpoints.values.sm)]: {
      fontSize: pxToRem(20),
    },
    [breakpoints.up(breakpoints.values.xs)]: {
      fontSize: pxToRem(20),
    },
  },
  h6: {
    fontSize: pxToRem(20),
    fontWeight: 600,
    letterSpacing: '0.15px',
  },

  subtitle1: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    letterSpacing: '0.1px',
    '&.subtitle2--small': {
      opacity: 0.4,
      fontSize: pxToRem(14),
    },
  },
  button: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    letterSpacing: '0.15px',
    textTransform: 'none',
  },
  body1: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  caption: {
    fontSize: pxToRem(14.5),
    fontWeight: 400,
    letterSpacing: '0.15px',
    [breakpoints.down(breakpoints.values.md)]: {
      fontSize: pxToRem(12),
    },
  },
  caption2: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  caption3: {
    fontSize: pxToRem(10),
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
}
export default typography
