import { alpha, Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import AppTokenInput from './AppTokenInput'
export const Amount = styled(Typography)`
  overflow: hidden;
`

export const TokenInputInnerContainer = styled(AppTokenInput.InnerContainer)`
  ${({ theme }) => css`
    overflow: hidden;
    background: ${alpha(theme.palette.primary[500], 0.2)};
    .app-token-input__select-token-button {
      padding-left: 0;
    }
  `}
`

export const TokenItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

interface TokenItemListProps {
  disableBgColor?: boolean
}
export const TokenItemList = styled(Box)<TokenItemListProps>`
  ${({ theme, disableBgColor }) => css`
    display: grid;
    grid-gap: 2px 0;
    background: ${disableBgColor
      ? 'unset'
      : alpha(theme.palette.primary[500], 0.2)};
    border-radius: ${theme.borderRadius.sm};
    padding: 12px;
    width: 100%;
  `}
`
