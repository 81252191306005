/**
 * This function only extracts the first {trimStart} and the last {trimEnd} characters.
 * @param {string} address
 * @param {number} trimStart
 * @param {number} trimEnd
 * @returns {string} a shortened address
 *
 * @example
 * // transforms "0x27f8760871945bb2476B8acA6D748BA8eB5d1879" to "0x27...879" with trimStart = 4 & trimend = 3
 */

export const getShortenedAddress = (
  address: string,
  trimStart = 6,
  trimEnd = 4,
): string => {
  return `${address.substring(0, trimStart)}...${address.substring(
    address.length - trimEnd,
    address.length,
  )}`
}
