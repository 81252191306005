import React, { ReactElement, useEffect, useRef } from 'react'
import { Container } from './Slidable.elements'

interface Props {
  children: React.ReactNode
  className?: string
  active: boolean
  durationInSec?: number
}
function Slidable({
  active,
  className,
  children,
  durationInSec = 0.3,
}: Props): ReactElement {
  const slidableRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const slidableEle = slidableRef.current
    if (!slidableEle) return
    if (!active) {
      slidableEle.style.maxHeight = `0`
      return
    }
    // For getting the height
    slidableEle.style.maxHeight = 'unset'
    slidableEle.style.visibility = 'hidden'
    slidableEle.style.position = 'absolute'
    slidableEle.style.display = 'block'
    slidableEle.style.transition = `max-height ${durationInSec}s ease-in-out`
    const targetHeight = slidableEle.offsetHeight

    // For showing the animation
    slidableEle.style.visibility = 'visible'
    slidableEle.style.position = 'relative'
    slidableEle.style.maxHeight = '0'

    const enableAnimationTimeout = setTimeout(() => {
      slidableEle.style.maxHeight = `${targetHeight}px`
    }, 10)
    const restoreMaxHeightTimeout = setTimeout(() => {
      slidableEle.style.maxHeight = 'unset'
      // unset max height after the animation is finished
    }, durationInSec * 1000 + 10)

    return () => {
      clearTimeout(enableAnimationTimeout)
      clearTimeout(restoreMaxHeightTimeout)
    }
  }, [active, durationInSec])
  return (
    <Container className={className} ref={slidableRef} data-testid="slidable">
      {children}
    </Container>
  )
}

export default React.memo(Slidable)
