import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { breakpoints } from './breakpoints'
import overrides from './overrides'
import palette from './palette'
import typography from './typography'
// platopia setting
export const PLATOPIA_DESKTOP_MAX_WIDTH = 960
// theme
export const theme = createTheme({
  typography,
  breakpoints,
  palette,
  components: overrides,
  borderRadius: { md: '20px', sm: '16px', rd: '999px' },
})

export default responsiveFontSizes(theme)
